import React, { Attributes, ReactChild } from "react";
import styled, { css } from "styled-components";
import { variant } from "styled-system";
import { typographyCSS } from "../Typography/styles";
import { Button as ChakraButton, Link as ChakraLink } from "@chakra-ui/react";
import NextLink from "next/link";
import {
  faFacebook,
  faFacebookSquare,
  faInstagram,
  faInstagramSquare,
  faLinkedin,
  faTwitter,
  faTwitterSquare,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type LinkProps = {
  width?: string;
  height?: string;
  fullWidth?: boolean;
};

export type ButtonProps = LinkProps & {
  variant?: "Button" | "Link" | undefined;
  size?: "sm" | "md";
  children?: React.ReactNode;
  themeName?:
    | "Outlined"
    | "Contained"
    | "Contained Light"
    | "Icon"
    | "Text"
    | undefined;
  title?: string;
  url?: string;
  linkToEntry?: any; //TODO: need to work on this
  iconVariant?: string;
  iconPlacement?: "Left" | "Right" | "Full" | undefined;
  disabled?: boolean;
  type?: "Button" | "Submit" | "Reset";
  ariaLabel?: string;
  style?: any;
  props?: any;
  onClick?: () => void;
} & Attributes;

const Link = styled.a<LinkProps>``;

const IconWrapper = styled.i`
  text-decoration: none;
  position: relative;

  &.left {
    margin-right: 0.5rem;
  }
  &.right {
    margin-left: 0.5rem;
  }
`;

const LinkWrapper = styled.div<LinkProps>`
  display: flex;
  position: relative;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  max-width: ${(props) => props.width};
  ${(props) => (props.height ? `height: ${props.height};` : "")};
  ${(props) => (props.height ? `max-height: ${props.height};` : "")};
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  min-width: ${(props) => props.width};
  min-height: ${(props) => props.height};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const ButtonLink = styled.button``;

const ButtonWrapper = styled.div<ButtonProps>`
  display: inline-block;
  position: relative;
  background: none;
  border: none;
  outline: none;
  border: 3px solid transparent;
  border-radius: 10em;
  transition: border-color 0.3s, color 0.3s, background 0.3s;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: fit-content;

  &:disabled {
    cursor: initial;
  }

  ${({ props, theme, themeName }) =>
    themeName === "Outlined"
      ? `

          border-color: ${theme.palette.primary.main};
          background-color: ${theme.palette.common.white};
          color: ${props?.color ? props.color : theme.palette.primary.main};


          a {
            text-decoration: none !important;

          }
          &:hover,
          &:focus {
            color: ${theme.palette.primary.contrastText};
            background: ${theme.palette.primary.light};
            border-color: ${theme.palette.primary.light};
          }
          &:disabled {
            border-color: ${theme.palette.primary.dark};
            color: ${theme.palette.primary.dark};
          }
        `
      : `
          color: ${
            props?.color ? props.color : theme.palette.primary.contrastText
          };

          background: ${theme.palette.primary.main};
          &:hover,
          &:focus {
            background: ${theme.palette.primary.light};
          }
          &:disabled {
            background: ${theme.palette.primary.dark};
          }

          a {
            color: ${
              props?.color ? props.color : theme.palette.primary.contrastText
            };
            text-decoration: none;
            font-family: ${theme.typography.h6.fontFamily};
            font-weight: ${theme.typography.h6.fontWeight};
            font-size: 1rem;
            text-decoration: none !important;


          }

        `}

  ${({ size }) =>
    size && size === "sm"
      ? css`
          border-width: 2px;
          ${typographyCSS("buttonSmall")}
          padding: 0.55em 1.75em;
        `
      : css`
          ${typographyCSS("buttonMedium")}
          padding: 0.95em 2em;
        `}

  ${({ fullWidth }) =>
    fullWidth &&
    `
    display: block;
    width: 100%;
  `}
`;

const getChakraVariant = (
  themeName: string | undefined
): string | undefined => {
  switch (themeName) {
    case "Outlined":
      return "brand-outline-accent";
    case "Contained":
      return "brand-solid-accent";
    case "Contained Light":
      return "brand-solid-light";
    case "Icon":
      return "Icon";
    case "Text":
      return undefined;
    default:
      return undefined;
  }
};

const Button = (data: ButtonProps): JSX.Element => {
  let {
    variant,
    title,
    url,
    themeName,
    linkToEntry,
    iconVariant,
    iconPlacement,
    children,
    style,
    ariaLabel,
  } = data;

  let urlFull = linkToEntry ? `/${linkToEntry?.fields?.slug}` : url;

  const chakraVariant = getChakraVariant(themeName);

  if (
    url &&
    process.env.NEXT_PUBLIC_SITE_URL &&
    url?.indexOf("https://") >= 0 &&
    url?.indexOf(process.env.NEXT_PUBLIC_SITE_URL) === -1 &&
    iconPlacement !== "Full"
  ) {
    //console.log("button data", data);
  }

  if (variant === "Button") {
    // return (
    //   <ButtonWrapper style={style} {...{ data }}>
    //     {title ? title : children}
    //   </ButtonWrapper>
    // );
    <ChakraLink data-chakra-link>{title}</ChakraLink>;
  } else if (
    themeName === "Icon" &&
    variant === "Link" &&
    iconPlacement === "Full"
  ) {
    // target="_blank" rel="noopener noreferrer"
    return (
      <LinkWrapper style={style} {...data}>
        <Link aria-label={title} href={urlFull} data-full-icon>
          {getFontIcon(iconVariant)}
        </Link>
      </LinkWrapper>
    );
  } else if (
    variant?.toString() === "Link" &&
    (themeName === "Outlined" ||
      themeName === "Contained" ||
      themeName === "Contained Light")
  ) {
    // //target="_blank" rel="noopener noreferrer"
    // return (
    //   <ButtonWrapper style={style} {...data}>
    //     {iconPlacement && iconPlacement === "Left" ? (
    //       <IconWrapper className={`${iconVariant} left`} />
    //     ) : null}
    //     <NextLink href={urlFull ?? "#"} passHref legacyBehavior>
    //       <ButtonLink aria-label={title} as="a" {...data}>
    //         {title ? title : children}
    //       </ButtonLink>
    //     </NextLink>
    //     {iconPlacement && iconPlacement === "Right" ? (
    //       <IconWrapper className={`${iconVariant}  right`} />
    //     ) : null}
    //   </ButtonWrapper>
    // );

    return (
      <NextLink href={urlFull ?? "#"} passHref legacyBehavior>
        <ChakraLink
          aria-label={ariaLabel}
          variant={chakraVariant}
          data-chakra-link
        >
          {title ? title : children}
        </ChakraLink>
      </NextLink>
    );
  }
  //target="_blank" rel="noopener noreferrer"
  // return (
  //   <LinkWrapper style={style} {...data}>
  //     {iconPlacement?.toLowerCase() === "left" ? (
  //       <IconWrapper className={`${iconVariant}  left`} />
  //     ) : null}
  //     <Link aria-label={title} href={urlFull}>
  //       {title ? title : children}
  //     </Link>

  //     {iconPlacement?.toLowerCase() === "right" ? (
  //       <IconWrapper className={`${iconVariant}  right`} />
  //     ) : null}
  //   </LinkWrapper>
  // );

  return (
    <NextLink href={urlFull ?? "#"} passHref legacyBehavior>
      <ChakraLink data-chakra-link variant={chakraVariant}>
        {title ? title : children}
      </ChakraLink>
    </NextLink>
  );
};

export default React.memo(Button);

const getFontIcon = (title?: string): React.ReactElement => {
  switch (title ? title.toString().toLowerCase() : "") {
    case "instagram":
      return <FontAwesomeIcon icon={faInstagram} />;
    case "twitter":
      return <FontAwesomeIcon icon={faTwitter} />;
    case "youtube":
      return <FontAwesomeIcon icon={faYoutube} />;
    case "linkedin":
      return <FontAwesomeIcon icon={faLinkedin} />;
    case "search":
      return <FontAwesomeIcon icon={faMagnifyingGlass} />;
    default:
      return <FontAwesomeIcon icon={faFacebookSquare} />;
  }
};
